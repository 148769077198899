import { MAIN_APP_DEV_LINK, MAIN_APP_LOCAL_LINK, MAIN_APP_PROD_LINK } from './links';

export function getMainAppFullPath(pathname: string) {
  if (typeof window === 'undefined') return MAIN_APP_PROD_LINK + pathname;
  // check with hostname
  if (window.location.hostname === 'localhost') {
    return MAIN_APP_LOCAL_LINK + pathname;
  }
  if (window.location.hostname.includes('dev')) {
    return MAIN_APP_DEV_LINK + pathname;
  }
  return MAIN_APP_PROD_LINK + pathname;
}
