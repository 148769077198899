import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import appleIcon57x57 from '../images/favicon/apple-icon-57x57.png';
import appleIcon60x60 from '../images/favicon/apple-icon-60x60.png';
import appleIcon72x72 from '../images/favicon/apple-icon-72x72.png';
import appleIcon76x76 from '../images/favicon/apple-icon-76x76.png';
import appleIcon114x114 from '../images/favicon/apple-icon-114x114.png';
import appleIcon120x120 from '../images/favicon/apple-icon-120x120.png';
import appleIcon144x144 from '../images/favicon/apple-icon-144x144.png';
import appleIcon152x152 from '../images/favicon/apple-icon-152x152.png';
import appleIcon180x180 from '../images/favicon/apple-icon-180x180.png';
import androidIcon192x192 from '../images/favicon/android-icon-192x192.png';
import favicon32x32 from '../images/favicon/favicon-32x32.png';
import favicon96x96 from '../images/favicon/favicon-96x96.png';
import favicon16x16 from '../images/favicon/favicon-16x16.png';
import msIcon144x144 from '../images/favicon/ms-icon-144x144.png';
import illumeMetaTag from '../images/illume-meta-tag.png';

const MetaTags: FC<{
  slug?: string;
  title: string;
  pretitle?: string;
  description?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
  ogType?: 'website' | 'article';
}> = ({
  slug = '',
  title = 'Blog | illume',
  description,
  ogTitle,
  ogDescription,
  ogImage,
  ogType = 'website',
  children,
}) => {
  return (
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <meta name='description' content={description ? description : 'Group notes for all'} />
      <meta
        name='keywords'
        content='notes,positivity,gratitude,self-care,meaningful and convenient'
      />
      <meta property='og:site_name' content='illume' />
      <meta property='og:title' content={ogTitle ? ogTitle : 'illume | better together'} />
      <meta
        property='og:description'
        content={ogDescription ? ogDescription : 'Create fun, stand-out ecards, group cards, and group gifts with illume. For birthdays, anniversaries, or any occasion. Join the new way of celebrating'}
      />
      <meta property='og:image' content={ogImage ? ogImage : illumeMetaTag} />
      <meta property='og:type' content={ogType} />
      <meta property='og:url' content={`https://illumenotes.com${slug}/`} />
      <meta name='twitter:title' content={ogTitle ? ogTitle : 'illume | better together'} />
      <meta
        name='twitter:description'
        content={ogDescription ? ogDescription : 'Create fun, stand-out ecards, group cards, and group gifts with illume. For birthdays, anniversaries, or any occasion. Join the new way of celebrating'}
      />
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:image' content={ogImage ? ogImage : illumeMetaTag} />
      <link rel='apple-touch-icon' sizes='57x57' href={appleIcon57x57} />
      <link rel='apple-touch-icon' sizes='60x60' href={appleIcon60x60} />
      <link rel='apple-touch-icon' sizes='72x72' href={appleIcon72x72} />
      <link rel='apple-touch-icon' sizes='76x76' href={appleIcon76x76} />
      <link rel='apple-touch-icon' sizes='114x114' href={appleIcon114x114} />
      <link rel='apple-touch-icon' sizes='120x120' href={appleIcon120x120} />
      <link rel='apple-touch-icon' sizes='144x144' href={appleIcon144x144} />
      <link rel='apple-touch-icon' sizes='152x152' href={appleIcon152x152} />
      <link rel='apple-touch-icon' sizes='180x180' href={appleIcon180x180} />
      <link rel='icon' type='image/png' sizes='192x192' href={androidIcon192x192} />
      <link rel='icon' type='image/png' sizes='32x32' href={favicon32x32} />
      <link rel='icon' type='image/png' sizes='96x96' href={favicon96x96} />
      <link rel='icon' type='image/png' sizes='16x16' href={favicon16x16} />
      <meta name='msapplication-TileColor' content='#ffffff' />
      <meta name='msapplication-TileImage' content={msIcon144x144} />
      <meta name='theme-color' content='#ffffff' />
      <title>{title}</title>
      {children}
    </Helmet>
  );
};

export default MetaTags;
