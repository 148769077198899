import { getMainAppFullPath } from './getUrl';

export const MAIN_APP_PROD_LINK = 'https://web.illumenotes.com';
export const MAIN_APP_PROD_ALTERNATIVE_LINK = 'https://tryillume.com';
export const MAIN_APP_DEV_LINK = 'https://web.dev.illumenotes.com';
export const MAIN_APP_LOCAL_LINK = 'http://localhost:8624';

// Marketing Site Navigation
export const INDEX_LINK = '/';
export const DMCA_LINK = '/dmca';
export const PRIVACY_LINK = '/privacy';
export const TERMS_LINK = '/terms';
export const POSTS_LINK = '/blog';
export const MARKETING_LINK = '/marketing';
export const ABOUT_LINK = '/about-us';
export const TEAMS_LINK = '/teams';
export const PROD_APP_TEAMS_LINK = 'https://web.illumenotes.com/teams';
export const ECARDS_LINK = '/ecards';
export const HOWITWORKS_LINK = '/how-it-works';
export const PRICING_B2C_LINK = '/pricing';
export const SLACK_ONBOARDING_LINK = '/illumeforslack/onboarding';
export const SLACK_LINK = '/illumeforslack';
export const SLACK_INSTALL_LINK = 'https://slack.com/apps/A053UUU0BAM';
export const SLACK_PRICING_LINK = '/illumeforslack/pricing';
export const OCCASIONS_LINK = '/occasions';
export const GIFTS_LINK = getMainAppFullPath('/marketplace/gifts');
export const SPLIT_ANY_GIFT_LINK = getMainAppFullPath('/splitanygifts');
export const GROUPCARDS_LINK = '/ecards/what-are-group-cards';

// illume webapp links
export const HOME_LINK = 'http://tryillume.com/';
export const SIGN_IN_LINK = 'https://web.illumenotes.com/login';
export const MAIN_PAGE_LINK = 'https://web.illumenotes.com ';
export const CREATE_CARD_LINK = 'https://web.illumenotes.com/marketplace/gifts ';
export const FAQS_LINK =
  'https://illumenotes.notion.site/illume-FAQs-8f73425cca43485a99b415fa2d073cb0';
export const EXPLORE_GIFTS = GIFTS_LINK;
export const SEND_FREE_GROUPCARD_LINK = 'https://web.illumenotes.com/create/info';
export const SEND_FREE_GROUPCARD_LINK_DEV = 'https://web.dev.illumenotes.com/create/info';
export const SEND_FREE_GROUPCARD_LINK_LOCAL = 'http://localhost:8624/create/info';
export const START_NOW_LINK = 'https://web.illumenotes.com/marketplace/gifts ';
export const MERCHANT_INTEREST_LINK = 'https://illumenotes.typeform.com/to/awMYOJVR';

// Social
export const INSTAGRAM_LINK = 'https://www.instagram.com/tryillume/';
export const FACEBOOK_LINK = 'https://www.facebook.com/illumenotes/';
export const TWITTER_LINK = 'https://twitter.com/illumenotes';
export const PLAYLIST_LINK =
  'https://open.spotify.com/playlist/0RLLccgpuFYyyCcI3pLayL?si=eaNlUFqqTeiNG6kx42xt0Q&nd=1';
export const STRIPE_LINK = 'http://stripe.com/';

// email
export const CONTACT_US_LINK = 'mailto:email@tryillume.com';
